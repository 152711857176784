export interface RouteRoot {
  data: Data;
}

export interface Data {
  routes: Route[];
}

export interface Route {
  gtfsId: string;
  shortName: string;
  longName: string;
  mode: HSLMode;
  patterns: Pattern[];
}

export interface Pattern {
  code: string;
  headsign: string;
  directionId: number;
}

export interface HSLRoute {
  gtfsId: string;
  shortName: string;
  longName: string;
  mode: HSLMode;
  code: string;
  headsign: string;
  directionId: number;
}

export enum HSLMode {
  BUS = "BUS",
  FERRY = "FERRY",
  TRAM = "TRAM",
  SUBWAY = "SUBWAY",
}
