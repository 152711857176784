import { configureStore } from "@reduxjs/toolkit";
import { realtimeLocationSlice } from "./slices/realtime-location-slice";
import { routeSlice } from "./slices/route-slice";

export const store = configureStore({
  reducer: {
    routes: routeSlice.reducer,
    realtimeLocations: realtimeLocationSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
