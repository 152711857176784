import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VPLocation } from "../Model/VPLocation";

interface RealtimeLocationState {
  currentLocations: VPLocation[];
  currentTopic: string;
}

const initRealtimeLocationState: RealtimeLocationState = {
  currentLocations: [],
  currentTopic: "",
};

export const realtimeLocationSlice = createSlice({
  name: "realtime-lolcations",
  initialState: initRealtimeLocationState,
  reducers: {
    addLocation(state, action: PayloadAction<string>) {
      const location: VPLocation = JSON.parse(action.payload);
      const existIdx = state.currentLocations.findIndex(
        (element) => element.VP.veh === location.VP.veh
      );
      if (existIdx !== -1) {
        state.currentLocations[existIdx] = location;
      } else {
        state.currentLocations.push(location);
      }
    },
    clearCurrentLocations(state) {
      state.currentLocations = [];
    },
    setCurrentTopic(state, action: PayloadAction<string>) {
      state.currentTopic = action.payload;
    },
  },
});

export const realtimeLocationsActions = realtimeLocationSlice.actions;
