import { makeStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchRoutePattern, routesActions } from "../slices/route-slice";
import { connect, IConnackPacket } from "mqtt";
import { realtimeLocationsActions } from "../slices/realtime-location-slice";
import { HSLRoute } from "../Model/RoutesRoot";
import { RouteSearchForm } from "./RouteSearchForm";
import { HslMap } from "./HslMap";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: theme.palette.primary.dark,
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   backgroundColor: "red",
    // },
    width: "100%",
  },
}));

const mqtt = connect("wss://mqtt.hsl.fi:443/");

export const Tracker = () => {
  const currentTopic = useAppSelector(
    (state) => state.realtimeLocations.currentTopic
  );

  const currentRoute = useAppSelector((state) => state.routes.currentRoute);

  useEffect(() => {
    if (currentRoute) {
      onRouteSelected(currentRoute);
    }
  }, [currentRoute]);

  const dispatch = useAppDispatch();

  const classes = useStyles();

  function subscribeTopic(route: HSLRoute) {
    const routeId = route.gtfsId.split(":")[1];
    const topic = `/hfp/v2/journey/ongoing/vp/+/+/+/${routeId}/${
      route.directionId + 1
    }/#`;
    dispatch(realtimeLocationsActions.setCurrentTopic(topic));
    mqtt
      .subscribe(topic)
      .on("connect", (packet: IConnackPacket) => {
        console.log("connected!", JSON.stringify(packet));
      })
      .on("message", (topic: string, payload: Buffer) => {
        dispatch(realtimeLocationsActions.addLocation(payload.toString()));
      });
  }

  function unSubscribeTopic() {
    mqtt.unsubscribe(currentTopic);
  }

  function onRouteSelected(route: HSLRoute) {
    unSubscribeTopic();
    dispatch(routesActions.filterRoute(undefined));
    dispatch(routesActions.clearRoutePattern());
    dispatch(realtimeLocationsActions.clearCurrentLocations());
    dispatch(fetchRoutePattern(route.code));
    subscribeTopic(route);
  }

  // function onRouteItemClick(route: HSLRoute) {
  //   unSubscribeTopic();
  //   dispatch(routesActions.filterRoute(undefined));
  //   dispatch(routesActions.clearRoutePattern());
  //   dispatch(realtimeLocationsActions.clearCurrentLocations());
  //   dispatch(fetchRoutePattern(route.code));
  //   subscribeTopic(route);
  // }

  return (
    <div className={classes.container}>
      <RouteSearchForm />
      <HslMap />
    </div>
  );
};
