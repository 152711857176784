import {
  Box,
  Paper,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Theme,
} from "@material-ui/core";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import TramIcon from "@material-ui/icons/Tram";
import SubwayIcon from "@material-ui/icons/Subway";

import { makeStyles } from "@material-ui/core/styles";
import { ChangeEvent, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchRoutes, routesActions } from "../slices/route-slice";
import SearchIcon from "@material-ui/icons/Search";
import { HSLMode, HSLRoute } from "../Model/RoutesRoot";
import { debounce } from "lodash";

interface RouteSearchFormProps {
  // onRouteItemClick: (route: HSLRoute) => void;
}

export const RouteSearchForm = (props: RouteSearchFormProps) => {
  const useStyles = makeStyles((theme: Theme) => ({
    routeSearch: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "absolute",
      zIndex: 1,
      alignItems: "center",
      marginTop: "1rem",
    },
    routeSearchForm: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      justifySelf: "center",
      width: 400,
      height: 50,
      [theme.breakpoints.down("sm")]: {
        width: 340,
        height: 44,
      },
    },
    routeInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    routeInputIconButton: {
      padding: 10,
    },
    routeSearchResult: {
      marginTop: "0.2rem",
    },
    root: {
      width: 400,
      [theme.breakpoints.down("sm")]: {
        width: 340,
      },
      backgroundColor: theme.palette.background.paper,
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const routeSearchRef = useRef<HTMLInputElement>(null);

  const matchedRoutes = useAppSelector((state) => state.routes.matchedRoutes);

  const onSearchRouteTextChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length === 0) {
      dispatch(routesActions.filterRoute(undefined));
      return;
    }
    dispatch(routesActions.filterRoute(event.target.value));
  };

  const debouncedEventHandler = useMemo(
    () => debounce(onSearchRouteTextChangeHandler, 300),
    []
  );

  useEffect(() => {
    dispatch(fetchRoutes());
  });

  const generateIcon = (route: HSLRoute) => {
    switch (route.mode) {
      case HSLMode.BUS: {
        return <DirectionsBusIcon />;
      }

      case HSLMode.TRAM: {
        return <TramIcon />;
      }

      case HSLMode.SUBWAY: {
        return <SubwayIcon />;
      }

      case HSLMode.FERRY: {
        return <DirectionsBoatIcon />;
      }
    }
  };

  return (
    <Box className={classes.routeSearch}>
      <Paper component="form" className={classes.routeSearchForm}>
        <IconButton
          type="submit"
          className={classes.routeInputIconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          inputRef={routeSearchRef}
          onChange={debouncedEventHandler}
          onSubmit={(e) => e.preventDefault()}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className={classes.routeInput}
          placeholder="Search Route"
          inputProps={{ "aria-label": "search route" }}
        />
      </Paper>
      {matchedRoutes.length > 0 && (
        <Paper className={classes.routeSearchResult}>
          <List className={classes.root}>
            {matchedRoutes.map((route, index) => {
              return (
                <ListItem
                  button={true}
                  divider={index < matchedRoutes.length - 1}
                  onClick={() => {
                    routeSearchRef.current!.value = "";
                    // props.onRouteItemClick(route);
                    dispatch(routesActions.setCurrentRoute(route));
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>{generateIcon(route)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={route.shortName}
                    secondary={route.headsign}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      )}
    </Box>
  );
};
