import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useAppSelector } from "../hooks";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import busMarker from "../assets/bus.png";
import userMarker from "../assets/user.png";
import tramMarker from "../assets/tram.png";
import ferryMarker from "../assets/ferry.png";
import subwayMarker from "../assets/subway.png";
import { HSLMode, HSLRoute } from "../Model/RoutesRoot";

export const HslMap = () => {
  const polyLineOptions = {
    strokeColor: "#64B5F6",
    strokeOpacity: 0.8,
    strokeWeight: 5,
    fillColor: "#64B5F6",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const googleMapsOptions = {
    overviewMapControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    mapId: "6cb6b4bd06f827d6",
  };

  const mapDefaultProps = {
    center: {
      lat: 60.192059,
      lng: 24.945831,
    },
    zoom: 11,
  };

  const realtimeLocations = useAppSelector(
    (state) => state.realtimeLocations.currentLocations
  );
  const currentRoutePattern = useAppSelector(
    (state) => state.routes.currentRoutePattern
  );

  const currentRoute = useAppSelector((state) => state.routes.currentRoute);

  const [currentUserLocation, setCurrentUserLocation] =
    useState<GeolocationCoordinates>();

  const [map, setMap] = useState<google.maps.Map>();
  const [mapLoaded, setMapLoaded] = useState(false);

  navigator.geolocation.getCurrentPosition(
    (position) => {
      setCurrentUserLocation(position.coords);
    },
    (error) => {
      console.log(error.message);
    }
  );

  useEffect(() => {
    if (mapLoaded) {
      fitBounds();
    }
  }, [currentRoutePattern]);

  function polylinePath() {
    return currentRoutePattern?.data.pattern.geometry.map((geo) => {
      return { lat: geo.lat, lng: geo.lon };
    });
  }

  // const fitBounds = useCallback(() => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   currentRoutePattern?.data.pattern.geometry.forEach((item) => {
  //     bounds.extend(new window.google.maps.LatLng(item.lat, item.lon));
  //   });
  //   map?.fitBounds(bounds);
  //   console.log("Fit bounds");
  // }, []);
  function fitBounds() {
    const bounds = new window.google.maps.LatLngBounds();
    currentRoutePattern?.data.pattern.geometry.forEach((item) => {
      bounds.extend(new window.google.maps.LatLng(item.lat, item.lon));
    });
    map?.fitBounds(bounds);
    console.log("Fit bounds");
  }

  function onMapLoadHandler(map: google.maps.Map) {
    setMap(map);
    setMapLoaded(true);
    map.setCenter(
      new window.google.maps.LatLng(
        mapDefaultProps.center.lat,
        mapDefaultProps.center.lng
      )
    );
  }

  function generateIcon(route?: HSLRoute): string {
    switch (route?.mode) {
      case HSLMode.BUS: {
        return busMarker;
      }
      case HSLMode.TRAM: {
        return tramMarker;
      }
      case HSLMode.FERRY: {
        return ferryMarker;
      }
      case HSLMode.SUBWAY: {
        return subwayMarker;
      }
      default: {
        return busMarker;
      }
    }
  }

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyA8VR_-sb32rSGnFvxnzfyewNK23Vpc1JY"
      mapIds={["6cb6b4bd06f827d6"]}
    >
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 0,
        }}
        zoom={10}
        options={googleMapsOptions}
        onLoad={onMapLoadHandler}
      >
        {realtimeLocations.map((location) => {
          return (
            <Marker
              icon={generateIcon(currentRoute)}
              position={
                new google.maps.LatLng(location.VP.lat!, location.VP.long!)
              }
            />
          );
        })}
        {currentRoutePattern && (
          <Polyline path={polylinePath()} options={polyLineOptions} />
        )}
        {currentUserLocation && (
          <Marker
            icon={userMarker}
            position={{
              lat: currentUserLocation.latitude,
              lng: currentUserLocation.longitude,
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};
