import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoutePatternRoot } from "../Model/RoutePatternRoot";
import { HSLRoute, RouteRoot } from "../Model/RoutesRoot";

interface RoutesState {
  allRoutes: HSLRoute[];
  matchedRoutes: HSLRoute[];
  currentRoutePattern: RoutePatternRoot | undefined;
  currentRoute: HSLRoute | undefined;
}

const initRouteStates = {
  allRoutes: [],
  matchedRoutes: [],
  currentRoutePattern: undefined,
  currentRoute: undefined,
} as RoutesState;

const client = new ApolloClient({
  uri: "https://api.digitransit.fi/routing/v1/routers/hsl/index/graphql",
  cache: new InMemoryCache(),
});

export const fetchRoutes = createAsyncThunk("route/fetchRoutes", async () => {
  console.log(`Fetch routes`);
  const result: RouteRoot = await client.query({
    query: gql`
      query {
        routes {
          gtfsId
          shortName
          longName
          mode
          patterns {
            code
            headsign
            directionId
          }
        }
      }
    `,
  });
  const hslRoutes: HSLRoute[] = result.data.routes.flatMap((route) => {
    return route.patterns.map((pattern) => {
      return {
        ...pattern,
        ...route,
      };
    });
  });
  return hslRoutes;
});

export const fetchRoutePattern = createAsyncThunk(
  "route/fetchRoutePattern",
  async (routeId: string) => {
    console.log(`Fetch rout pattern`);
    try {
      const result: RoutePatternRoot = await client.query({
        query: gql`
          query Pattern($routeId: String!) {
            pattern(id: $routeId) {
              geometry {
                lat
                lon
              }
            }
          }
        `,
        variables: {
          routeId,
        },
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const routeSlice = createSlice({
  name: "routes",
  initialState: initRouteStates,
  reducers: {
    filterRoute(state, action: PayloadAction<string | undefined>) {
      if (action.payload === undefined) {
        state.matchedRoutes = [];
      } else {
        const matchedRoutes = state.allRoutes.filter(
          (route) =>
            route.longName.includes(action.payload!) ||
            route.shortName.includes(action.payload!)
        );
        state.matchedRoutes = matchedRoutes;
      }
    },
    clearRoutePattern(state) {
      state.currentRoutePattern = undefined;
    },
    setCurrentRoute(state, action: PayloadAction<HSLRoute>) {
      state.currentRoute = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRoutes.fulfilled, (state, { payload }) => {
      state.allRoutes = payload;
    });
    builder.addCase(fetchRoutePattern.fulfilled, (state, { payload }) => {
      state.currentRoutePattern = payload;
    });
  },
});

export const routesActions = routeSlice.actions;
